import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG } from '../shared/api.config';
import { AuditoryInputDTO } from '../model/auditory';

@Injectable({
  providedIn: 'root',
})
export class AuditoryService {
  constructor(private http: HttpClient) {}

  saveAuditory(auditoryInputDTO: AuditoryInputDTO): Observable<any> {
    const url = API_CONFIG.saveAuditory;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, auditoryInputDTO, {
      headers,
      withCredentials: true,
    });
  }
}
