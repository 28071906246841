import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { JwtModule } from '@auth0/angular-jwt';
import { API_CONFIG } from '../shared/api.config';
import { SharedModule } from '../shared/shared.module';

export function tokenGetter() {
  return localStorage.getItem(API_CONFIG.localStorage.access_token);
}

@NgModule({
  declarations: [LoginComponent],
  imports: [
    MatProgressSpinnerModule,
    TranslateModule,
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
  ],
})
export class AuthModule {}
