import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  formatDate(dateString: string): string {
    const dateObj = new Date(dateString);
    if (isNaN(dateObj.getTime())) {
      return 'Invalid date';
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return dateObj.toLocaleString(undefined, options);
  }

  // Puedes agregar más funciones de utilidad aquí
}
