<mat-toolbar>
  <mat-toolbar-row class="mat-toolbar">
    <div class="logo-container">
      <img src="./assets/images/logo_huaquillas.png" alt="logo" class="logo">
      <div class="greeting">
        {{'hello' | translate}} {{name}}
      </div>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="flex-end" class="right-nav-buttons">
      <span class="username">{{username}}</span>
      <ul fxLayout fxLayoutGap="20px" class="navigation-items">

        <li>
          <button mat-icon-button [matMenuTriggerFor]="language">
            <mat-icon matBadge="{{selectedLanguage}}">language</mat-icon>
          </button>
          <mat-menu #language="matMenu">
            <button mat-menu-item (click)="toogleLanguage('es')">
              <span>{{ 'language.spanish' | translate }}</span>
            </button>
            <button mat-menu-item (click)="toogleLanguage('en')">
              <span>{{ 'language.english' | translate }}</span>
            </button>
            <button mat-menu-item (click)="toogleLanguage('gal')">
              <span>{{ 'language.galician' | translate }}</span>
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
