<div class="container">
  <h2 mat-dialog-title id="title">{{ data.title }}</h2>
  <mat-dialog-content>
    <p>{{ data.content }}</p>
    <mat-form-field class="child-alone">
      <mat-label for="textInput">{{ data.inputLabel}}</mat-label>
      <input matInput type="email" [(ngModel)]="email" name="email" #emailInput="ngModel" required email>
      <mat-error *ngIf="emailInput.hasError('required')">{{'required'| translate}}</mat-error>
      <mat-error *ngIf="emailInput.hasError('email')">{{'error.email.format'| translate}}</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="button-container">
      <button class="buttons" [disabled]="!emailInput.valid" mat-raised-button color="primary"
              (click)="onAccept()">{{'accept'|translate}}</button>
    </div>
  </mat-dialog-actions>
</div>
