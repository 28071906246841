import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoggerService } from 'src/app/services/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { API_CONFIG } from 'src/app/shared/api.config';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  private returnUrl = '/';
  selectedLanguage = this.translateService.currentLang;
  username: string = '';
  name: string = '';

  constructor(
    private router: Router,
    private logger: LoggerService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.authService.getUser().subscribe((user) => {
      if (user) {
        this.name = user.name;
        this.username = user.username;
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
        this.logger.info('NavigationBarComponent returnUrl: ' + this.returnUrl);
      }
    });
  }

  toogleLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translateService.use(lang);
  }
}
