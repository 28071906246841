import { environment } from '../../environments/environment';

export const API_CONFIG = {
  authUrl: environment.authBaseUrl,
  localStorage: {
    access_token: 'access_token',
    username: 'username',
    name: 'name',
    idindividualsmaster: 'idindividualsmaster',
    email: 'email',
    sede_url: 'sedeUrl'
  },
  getTaxList: environment.authBaseUrl + '/tax/list',
  validateToken: environment.authBaseUrl + '/auth/token/validate',
  getUserData: environment.authBaseUrl + '/auth/get-user-data',
  getDataMasters: environment.authBaseUrl + '/datamaster/values',
  generateToken: environment.authBaseUrl + '/datamaster/token',
  saveTransaction: environment.authBaseUrl + '/transaction',
  saveRefund: environment.authBaseUrl + '/refund',
  getMyPayment: environment.adminBaseUrl + '/transaction/my-payments',
  saveAuditory: environment.authBaseUrl + '/auditory',
  checkRegistryNull: environment.authBaseUrl + '/transaction/{idTransaction}/registry',

};
