import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class XhrInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/transaction') || req.url.endsWith('/refund')) {
      console.log('Intercepted request:', req);

      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            console.log('Intercepted response:', event);
          }
        })
      );
    }
    return next.handle(req);
  }
}
