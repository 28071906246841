<h2 class="title">{{'tax_payment.huaquillas.title' | translate}}</h2>
<div class="div-table">
  <div class="searcher">
    <input (keyup)="applyFilter($event)" placeholder="{{'search'|translate}}">
  </div>
  <table mat-table [dataSource]="dataSource" class="tax-table">
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="arrow-button">
        <button mat-icon-button class="arrow" (click)="toggleRow(element)" *ngIf="element.documentMasterTypeDesc">
          <mat-icon>{{ element.expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.tax_description" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.documentMasterTypeName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.tax_type" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.documentMasterClassDesc }}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.tax_price" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="price_column">{{ element.taxesPrice | number : "1.2-2" }} {{currencySymbol}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{"actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button color="primary" (click)="payTax(element)">
          {{'pay'|translate}}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" *ngIf="element.detailRow">
          {{ element.documentMasterTypeDesc }}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"></tr>
  </table>

</div>
