import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(req);
  }

  private buildHeadersBase(contentType): HttpHeaders {
    let headers = new HttpHeaders();
    if (contentType) headers = headers.set('Content-Type', contentType);
    headers = headers.set('Authorization', 'Bearer ' + this.auth.getToken());
    return headers;
  }

  public buildHeaders(): HttpHeaders {
    return this.buildHeadersBase('application/json;charset=UTF-8');
  }

  public buildUnauthorizedHeaders(): HttpHeaders {
    return new HttpHeaders().set(
      'Content-Type',
      'application/json;charset=UTF-8'
    );
  }
}
