<div class="tax-container">
  <div *ngIf="isLoggedIn" class="payment-tabs">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="tab_div">
      <mat-tab label="{{'tax_payment.title' | translate }}">
        <app-tax-payment></app-tax-payment>

      </mat-tab>
      <mat-tab label="{{'tax_payment.my_payment' | translate }}">
        <app-my-payments></app-my-payments>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div *ngIf="!isLoggedIn">
    NO ESTOY LOGUEADO MAIN
  </div>
</div>
