import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TransactionInput } from 'src/app/model/transaction';
import { TaxService } from 'src/app/services/tax.service';
import { ErrorDialogComponent } from 'src/app/shared/components/dialogs/error-dialog/error-dialog.component';

@Component({
  selector: 'app-confirm-refund',
  templateUrl: './confirm-refund.component.html',
  styleUrls: ['./confirm-refund.component.scss']
})
export class ConfirmRefundComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRefundComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionInput,
    private taxService: TaxService,
    private dialog: MatDialog,
    private translate: TranslateService,

  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


  confirmRefund(): void {
    this.taxService.isRegistryNull(this.data.idTransaction).subscribe({
      next: (isRegistryNull: boolean) => {
        if (isRegistryNull) {
          this.dialogRef.close(this.data);
        } else {
          this.showErrorDialog();
        }
      },
      error: (err: any) => {
        console.error('Error al comprobar el estado del registro:', err);
      }
    });
  }

  showErrorDialog(): void {
    this.dialog.open(ErrorDialogComponent, {
      data: { message: this.translate.instant('refund_denied') }
    });
  }
}
