<div class="container">
  <h2 mat-dialog-title id="title">{{ data.title }}</h2>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div class="status_container">
      <p class="action-tax">
        <mat-icon [class]="status_icon">{{ status_icon }}</mat-icon>
        <span>{{ action_translate }}</span>
      </p>
    </div>
    <div fxLayout="row" fxFill fxLayoutAlign="space-around" class="data-container">
      <div fxLayout="column" fxFlex="20" class="card-container">
        <mat-icon class="card_icon">{{ payment_icon }}</mat-icon>
      </div>
      <div fxLayout="column" fxFlex="80">
        <h3>{{ 'payment.sumary' | translate }}</h3>

        <div class="data-values">
          <div class="label-value-data" *ngIf="data.idtransaction != null">
            <span class="label-payment">{{ 'tax_payment.idtransaction' | translate }}</span>
            <span class="value-payment">{{ data.idtransaction }}</span>
          </div>

          <div class="label-value-data" *ngIf="data.date != null">
            <span class="label-payment">{{ 'payment.date' | translate }}</span>
            <span class="value-payment">{{ data.date }}</span>
          </div>
          <div class="label-value-data" *ngIf="data.actionDetail != null">
            <span class="label-payment">{{ 'payment.reason' | translate }}</span>
            <span class="value-payment">{{ reason }}</span>
          </div>
          <div class="label-value-data">
            <span class="label-payment">{{ 'payment.tax' | translate }}</span>
            <span class="value-payment">{{ data.tax }}</span>
          </div>
          <div *ngIf="data.description != null" class="label-value-data">
            <span class="label-payment">{{ 'payment.description' | translate }}</span>
            <span class="value-payment">{{ data.description }}</span>
          </div>
        </div>

        <h3>{{ 'payment.data' | translate }}</h3>
        <div>

          <div class="data-values">
            <div class="label-value-data">
              <span class="label-payment">{{ 'payment.fullname' | translate }}</span>
              <span class="value-payment">{{ data.fullname }}</span>
            </div>
            <div class="label-value-data">
              <span class="label-payment">{{ 'payment.amount' | translate }}</span>
              <span class="value-payment amount">{{data.currency}} {{ data.amount }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="button-container">
      <button class="buttons" mat-raised-button color="primary" (click)="onClose()">{{ 'button.close' | translate
        }}</button>
    </div>
  </mat-dialog-actions>
</div>
