import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'input-email-dialog',
  templateUrl: './input-email-dialog.component.html',
  styleUrls: ['./input-email-dialog.component.scss'],
})
export class InputEmailDialogComponent {
  @Output() accept: EventEmitter<string> = new EventEmitter<string>();
  email: string = '';

  constructor(
    public dialogRef: MatDialogRef<InputEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onAccept(): void {
    this.accept.emit(this.email);
    this.dialogRef.close();
  }
}
