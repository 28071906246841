import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.initTranslations();
  }

  private initTranslations(): void {
    this.translate
      .get('paginator.itemsPerPageLabel')
      .subscribe((res: string) => {
        this.itemsPerPageLabel = res;
      });
  }
}
