import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent {
  @Output() close: EventEmitter<void> = new EventEmitter();
  payment_icon: string;
  status_icon: string = 'cancel';
  action_translate: string;
  title: string;
  reason: string;
  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cdr: ChangeDetectorRef
  ) {
    this.setupIcons(data.action);
    if (data.actionDetail != undefined) {
      this.translateReason(data.actionDetail);
    }
    this.action_translate = this.translate.instant(data.action);
  }

  setupIcons(action: any) {
    switch (action) {
      case 'payment.success':
      case 'payment.pending':
        this.payment_icon = 'credit_score';
        this.status_icon = 'check_circle';
        break;
      case 'payment.refund.success':
        this.payment_icon = 'local_atm';
        this.status_icon = 'check_circle';
        break;
      default:
        this.payment_icon = 'credit_card_off';
        break;
    }
  }

  translateReason(actionDetail: number) {
    switch (actionDetail) {
      case 1:
        this.reason = this.translate.instant('reviewed_transaction');
        break;
      case 9:
        this.reason = this.translate.instant('denied_transaction');
        break;
      case 11:
        this.reason = this.translate.instant('rejected_fraud');
        break;
      case 12:
        this.reason = this.translate.instant('card_black_list');
        break;
      default:
        this.reason = this.translate.instant('error_transaction');
        break;
    }
  }

  onClose(): void {
    this.close.emit();
    this.dialogRef.close();
  }
}
