import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { DataMasterValue, DataMasterValuesDTO } from 'src/app/model/datamaster';
import { Tax } from 'src/app/model/tax';
import { Transaction } from 'src/app/model/transaction';
import { AuditoryService } from 'src/app/services/auditory.service';
import { DataMasterService } from 'src/app/services/data-master.service';
import { TaxService } from 'src/app/services/tax.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { UtilsService } from 'src/app/services/utils.service';
import { API_CONFIG } from 'src/app/shared/api.config';
import { ConfirmComponent } from 'src/app/shared/components/dialogs/confirm/confirm.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tax-payment',
  templateUrl: './tax-payment.component.html',
  styleUrls: ['./tax-payment.component.scss'],
})
export class TaxPaymentComponent implements AfterViewInit {
  tax: Tax[];
  currencySymbol: string;
  currency: string;
  displayedColumns: string[] = [
    'expand',
    'description',
    'type',
    'price',
    'actions',
  ];
  paymentCheckout: PaymentCheckoutModal;
  dataSource: MatTableDataSource<Tax>;
  expandedElement: Tax | null;
  idIndividualMaster: number;
  taxDescription: string;
  documentMasterClass: string;
  dataMasterDTO: DataMasterValuesDTO;
  paymentApiUrl: string;
  devReference: string;
  idDocumentMasterType: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private taxService: TaxService,
    private authService: AuthService,
    private dataMaster: DataMasterService,
    public dialog: MatDialog,
    private transactionService: TransactionService,
    private auditoryService: AuditoryService,
    private utilsService: UtilsService

  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.getTaxList();
      this.idIndividualMaster = parseInt(
        localStorage.getItem(API_CONFIG.localStorage.idindividualsmaster) ||
          '0',
        10
      );
      if (isNaN(this.idIndividualMaster)) {
        console.error(
          'Failed to retrieve idIndividualMaster from localStorage.'
        );
      }
      this.dataMaster.getDataMasterValues().subscribe((data) => {
        this.dataMasterDTO = data;
        const baseUrl = this.getValueNameByValueCode(
          data.dataMasterValues,
          'URL'
        );
        localStorage.setItem('baseUrl', baseUrl);
        this.paymentApiUrl = this.getValueNameByValueCode(
          data.dataMasterValues,
          'URL'
        ).concat('init_reference/');
        this.devReference = this.getValueNameByValueCode(
          data.dataMasterValues,
          'REFERENCE'
        );
        this.currency = data.currency;
        switch (this.currency) {
          case 'USD':
            this.currencySymbol = '$';
            break;
          case 'EURO':
            this.currencySymbol = '€';
            break;
          default:
            this.currencySymbol = '';
            break;
        }
      });
    }
  }

  getValueNameByValueCode(
    data: DataMasterValue[],
    code: string
  ): string | undefined {
    const valueObject = data.find((item) => item.valueCode === code);
    return valueObject ? valueObject.valueName : undefined;
  }

  ngAfterViewInit(): void {
    this.loadPaymentScript();
  }

  loadPaymentScript(): void {
    const script = document.createElement('script');
    script.src = environment.paymentCheckoutScript;
    script.onload = () => {
      console.log('Nuvei Payment script loaded.');
    };
    document.body.appendChild(script);
  }

  toggleRow(element: Tax) {
    element.expand = !element.expand;
    this.dataSource.data = this.buildDataSource(this.tax);
  }

  buildDataSource(data: Tax[]): any[] {
    const expandedData: any[] = [];
    data.forEach((item) => {
      expandedData.push(item);
      if (item.expand) {
        expandedData.push({ ...item, detailRow: true });
      }
    });
    return expandedData;
  }

  isExpansionDetailRow = (index: number, row: any) => !!row.detailRow;

  /**
   * Get all taxes with pagination parameters
   *
   */
  getTaxList() {
    this.taxService.getAllTaxes().subscribe((data) => {
      this.tax = data;
      this.dataSource = new MatTableDataSource(data);
    });
  }

  /**
   * Open modal to pay
   *
   */
  payTax(tax: Tax): void {
    this.taxDescription = tax.documentMasterTypeDesc;
    this.documentMasterClass = tax.documentMasterClassDesc;
    this.idDocumentMasterType = tax.idDocumentMasterType;
    this.dataMaster.generateToken().subscribe((data) => {
      if (data != null) {
        this.initializePaymentModal();
        this.initiateTransaction(tax, data).then((reference) => {
          if (reference != null) {
            this.openPaymentModal(reference);
          }
        });
      }
    });
  }

  /**
   * Configuration paymentez modal
   *
   */
  async initiateTransaction(tax: Tax, authToken: string) {
    const apiUrl = this.paymentApiUrl;
    const amount = tax.taxesPrice.toFixed(2);
    const email = localStorage.getItem(API_CONFIG.localStorage.email);
    const idUser: string = String(this.idIndividualMaster);
    const devRef = this.devReference;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Auth-Token': authToken,
      },
      body: JSON.stringify({
        locale: 'es',
        order: {
          description: tax.documentMasterTypeName,
          amount: amount,
          vat: 0,
          taxable_amount: 0,
          tax_percentage: 0,
          dev_reference: devRef,
        },
        user: {
          id: idUser,
          email: email,
        },
        conf: {
          style_version: '2',
          theme: {
            primary_color: '#00225a',
          },
        },
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.reference != undefined) {
            return data.reference;
          } else {
            console.error('Error: ' + data.error.description);
          }
          return null;
        });
      return response;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  openDialogConfirm(
    transactionDTO: Transaction,
    action: string,
    actionDetail: number
  ): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '45%',
      data: {
        idtransaction: transactionDTO.idTransaction,
        title: transactionDTO.documentMasterTypeName,
        action: action,
        date: this.utilsService.formatDate(transactionDTO.paymentDate + 'Z'),
        tax: transactionDTO.documentMasterTypeName,
        description: transactionDTO.description,
        fullname: localStorage.getItem(API_CONFIG.localStorage.username),
        amount: transactionDTO.amount,
        currency: this.currencySymbol,
        actionDetail: actionDetail,
      },
    });
    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.cdr.detectChanges();
    });
    dialogRef.componentInstance.close.subscribe(() => {
      this.transactionService.notifyTransactionCompleted();
    });
  }

  adjustPaymentTimezone(date: string): Date {
    const originalDate = new Date(date);
    const timezoneOffset = originalDate.getTimezoneOffset();
    return new Date(originalDate.getTime() - timezoneOffset * 60000);
  }

  initializePaymentModal() {
    //@ts-ignore
    this.paymentCheckout = new window.PaymentCheckout.modal({
      env_mode: environment.envMode,
      onOpen: () => {
        console.log('modal open');
      },
      onClose: () => {
        console.log('modal closed');
      },
      onResponse: (response: any) => {
        const transactionDTO: Transaction = {
          idIndividualMaster: this.idIndividualMaster,
          idTransaction: response.transaction.id,
          amount: response.transaction.amount,
          paymentDate: response.transaction.payment_date,
          description: this.taxDescription,
          status: response.transaction.status,
          statusDetail: response.transaction.status_detail,
          currency: this.currency,
          documentMasterClassDesc: this.documentMasterClass,
          documentMasterTypeName: response.transaction.product_description,
          email: localStorage.getItem(API_CONFIG.localStorage.email),
          idDocumentMasterType: this.idDocumentMasterType,
          authorizationCode: response.transaction.authorization_code
        };
        let action = 'payment.failure';
        if (response.transaction.status_detail === 3) {
          action = 'payment.success';
        } else if (response.transaction.status_detail === 1) {
          action = 'payment.pending';
        }

        let actionDetail =
          response.transaction.status_detail !== 3
            ? response.transaction.status_detail
            : undefined;

        const auditoryInputDTO = {
          idTransaction: transactionDTO.idTransaction,
          request: environment.transaction,
          body: JSON.stringify(response),
          dateAuditory: new Date().toISOString(),
        };

        this.auditoryService.saveAuditory(auditoryInputDTO).subscribe({
          next: () => console.log('Auditoría guardada correctamente'),
          error: (err) => console.error('Error al guardar la auditoría:', err),
        });

        this.openDialogConfirm(transactionDTO, action, actionDetail);

        this.taxService.saveTransaction(transactionDTO).subscribe({
          error: () => {
            console.error('Error al guardar la transacción: Error en la transacción con ID', transactionDTO.idTransaction);
          },
        });
      },
    });

    window.addEventListener('popstate', () => {
      this.paymentCheckout.close();
    });
  }

  openPaymentModal(reference: any) {
    this.paymentCheckout.open({ reference: reference });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
