import { Component } from '@angular/core';
import { API_CONFIG } from 'src/app/shared/api.config';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {
  constructor() {}

  redirectToBaseUrl(event: MouseEvent): void {
    event.preventDefault();
    const sedeUrl = localStorage.getItem(API_CONFIG.localStorage.sede_url);
    if (sedeUrl) {
      window.location.href = sedeUrl;
    } else {
      console.error('Base URL not found in local storage.');
    }
  }
}
