import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { AuthService } from 'src/app/auth/auth.service';

interface ROUTE {
  icon?: string;
  route?: string;
  title?: string;
  alloweModules?: Array<string>;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild('commandbarSidenav') public sidenav: MatSidenav;

  protected subscription: Subscription;

  constructor(
    private commandBarSidenavService: SidenavService,
    private authService: AuthService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.logger.info('NavComponent: ngOnInit()');
    this.commandBarSidenavService.setSidenav(this.sidenav);
  }

  /*
   * Comprobamos si está logueado correctamente y las urls a las que se puede acceder sin login
   */
  public isAuthenticated() {
    return this.authService.isLoggedIn();
  }

  public ngOnDestroy() {
    this.logger.info('NavComponent: ngOnDestroy()');
  }
}
