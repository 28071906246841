import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainHomeComponent } from './main-home/main-home.component';
import { TaxPaymentComponent } from './tax-payment/tax-payment.component';
import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
  { path: 'main', component: MainHomeComponent, canActivate: [AuthGuard] },
  {
    path: 'tax-payment',
    component: TaxPaymentComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
