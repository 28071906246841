import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../shared/api.config';
import { User } from '../model/user';

export const TOKEN_NAME: string = 'jwt_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string;
  private isAuthenticated = new BehaviorSubject<boolean>(false);
  private userSubject = new BehaviorSubject<any>(
    this.getUserFromLocalStorage()
  );
  constructor(private http: HttpClient) {}

  validateToken(token: string): Observable<boolean> {
    return this.http
      .post<any>(API_CONFIG.validateToken, { token }, { withCredentials: true })
      .pipe(
        map((response) => {
          if (response) {
            localStorage.setItem(API_CONFIG.localStorage.access_token, token);
            this.isAuthenticated.next(true);
            return true;
          } else {
            this.isAuthenticated.next(false);
            return false;
          }
        })
      );
  }

  setUser(user: {
    name: string;
    surname: string;
    secondSurname: string;
    email: string;
    idindividualsmaster: any;
    username: string;
  }) {
    localStorage.setItem(
      API_CONFIG.localStorage.idindividualsmaster,
      user.idindividualsmaster
    );
    localStorage.setItem(API_CONFIG.localStorage.name, user.name);
    localStorage.setItem(API_CONFIG.localStorage.username, user.username);
    localStorage.setItem(API_CONFIG.localStorage.email, user.email);

    this.userSubject.next(user);
  }

  getUser() {
    return this.userSubject.asObservable();
  }

  private getUserFromLocalStorage() {
    const name = localStorage.getItem(API_CONFIG.localStorage.name);
    const username = localStorage.getItem(API_CONFIG.localStorage.username);
    const email = localStorage.getItem(API_CONFIG.localStorage.email);
    if (name && username) {
      return { name, username, email };
    }
    return null;
  }

  errorMessage(error: string) {
    return error;
  }

  isLoggedIn(): boolean {
    return localStorage.getItem(API_CONFIG.localStorage.access_token) !== null;
  }

  logout(): void {
    localStorage.removeItem(API_CONFIG.localStorage.access_token);
    this.clearUser();
    this.isAuthenticated.next(false);
  }

  getToken() {
    return localStorage.getItem(API_CONFIG.localStorage.access_token);
  }

  getUserData(): Observable<User> {
    const url = API_CONFIG.getUserData;
    return this.http.get<User>(url, { withCredentials: true });
  }

  clearUser() {
    localStorage.removeItem('name');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    this.userSubject.next(null);
  }
}
