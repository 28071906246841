<div class="container">
  <h2 mat-dialog-title id="title">{{ 'confirm_refund' | translate }}</h2>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div class="status_container">
      <p class="action-tax">
        <span>{{ 'confirm_refund_message' | translate }}</span>
      </p>
    </div>
    <div fxLayout="row" fxFill fxLayoutAlign="space-around" class="data-container">
      <div fxLayout="column" fxFlex="20" class="card-container">
        <mat-icon class="card_icon">currency_exchange</mat-icon>
      </div>
      <div fxLayout="column" fxFlex="80">
        <h3>{{ 'payment.sumary' | translate }}</h3>
        <div class="data-values">
          <div class="label-value-data" *ngIf="data.idTransaction != null">
            <span class="label-payment">{{ 'tax_payment.idtransaction' | translate }}</span>
            <span class="value-payment">{{ data.idTransaction }}</span>
          </div>
          <div class="label-value-data" *ngIf="data.insertdate != null">
            <span class="label-payment">{{ 'payment.date' | translate }}</span>
            <span class="value-payment">{{ data.insertdate }}</span>
          </div>
          <div class="label-value-data">
            <span class="label-payment">{{ 'payment.tax' | translate }}</span>
            <span class="value-payment">{{ data.documentMasterTypeName }}</span>
          </div>
          <div *ngIf="data.description != null" class="label-value-data">
            <span class="label-payment">{{ 'payment.description' | translate }}</span>
            <span class="value-payment">{{ data.description }}</span>
          </div>
          <div class="label-value-data">
            <span class="label-payment">{{ 'payment.amount' | translate }}</span>
            <span class="value-payment amount">{{ data.currency }} {{ data.amount }}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="button-container">
      <button class="buttons" mat-raised-button color="primary" (click)="confirmRefund()">{{ 'button.confirm' | translate }}</button>
      <button class="buttons" mat-raised-button color="warn" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
