import { Injectable } from '@angular/core';
import { MatSnackBarComponent } from '../components/mat-snack-bar/mat-snack-bar.component';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutModalComponent } from '../shared/components/dialogs/logout-modal/logout-modal.component';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBarComponent,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          if (err.error != null && err.error.message != null) {
            let message = err.error.message;
            if (err.error.code != null && err.error.code == 901) {
              message = 'BEAN_VALIDATION_ERROR';
            }
            this.snackBar.openSnackBar(
              this.translate.instant(message),
              this.translate.instant('CLOSE'),
              'red-snackbar'
            );
          }
        } else if (err.status === 403) {
          this.authService.logout();
          this.router.navigateByUrl('/login');
          this.snackBar.openSnackBar(
            this.translate.instant('error.incorrect_login'),
            this.translate.instant('CLOSE'),
            'red-snackbar'
          );
        } else if (err.status === 401) {
          this.dialog.open(LogoutModalComponent, {width: '35%', disableClose: true });
          if (this.isLoginPage()) {
            this.authService.logout();
          } else {
            this.authService.logout();
            this.router.navigateByUrl('/login');
          }
        } else if (err.status === 500) {
          this.snackBar.openSnackBar(
            this.translate.instant('DATABASE_QUERY_ERROR'),
            this.translate.instant('CLOSE'),
            'red-snackbar'
          );
        } else {
          if (err.url.includes('token')) {
            this.snackBar.openSnackBar(
              this.translate.instant(err.error.error_description),
              this.translate.instant('CLOSE'),
              'red-snackbar'
            );
          } else {
            this.snackBar.openSnackBar(
              err.message,
              this.translate.instant('CLOSE'),
              'red-snackbar'
            );
          }
        }
        return throwError(err);
      })
    );
  }

  private isLoginPage(): boolean {
    return this.router.url == '/login';
  }
}
