import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG } from '../shared/api.config';
import { Tax } from '../model/tax';
import { Transaction } from '../model/transaction';
import { TransactionInput } from '../model/transaction';
import { Refund } from '../model/refund';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  constructor(private http: HttpClient) {}

  getAllTaxes(): Observable<Tax[]> {
    const url = API_CONFIG.getTaxList;
    return this.http.get<Tax[]>(url, { withCredentials: true });
  }

  saveTransaction(transaction: Transaction): Observable<any> {
    const url = API_CONFIG.saveTransaction;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, transaction, {
      headers,
      withCredentials: true,
    });
  }

  getMyPayments(): Observable<TransactionInput[]> {
    const url = API_CONFIG.getMyPayment;
    return this.http.get<TransactionInput[]>(url, { withCredentials: true });
  }

  saveRefund(refund: Refund): Observable<any> {
    const url = API_CONFIG.saveRefund;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, refund, {
      headers,
      withCredentials: true,
    });
  }

  isRegistryNull(idTransaction: string): Observable<boolean> {
    const url = API_CONFIG.checkRegistryNull.replace('{idTransaction}', idTransaction);
    return this.http.get<boolean>(url, { withCredentials: true });
  }
}
