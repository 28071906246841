export class Transaction {
  idIndividualMaster: number;
  idTransaction: string;
  amount: number;
  paymentDate: string;
  description?: string;
  status: string;
  statusDetail: number;
  currency: string;
  documentMasterClassDesc: string;
  documentMasterTypeName: string;
  email: string;
  idDocumentMasterType: number;
  authorizationCode?: string;
}

export class TransactionInput {
  idIndividualMaster: number;
  idTransaction: string;
  amount: number;
  datePayment: string;
  description: string;
  statusDetail: number;
  insertdate: string;
  currency: string;
  documentMasterClassDesc: string;
  documentMasterTypeName: string;
  expand?: boolean = false;
  status: string;
  csv?: string;
  pendingStatus?: number;
  pendingRefund?: number;
  urlCsv?: string;
  idregistry?: number;
  registryCode?: string;
  urlRegistry?: string;
  authorizationCode?: string;
}
