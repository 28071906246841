import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomDateAdapter } from './custom-date-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { InputEmailDialogComponent } from './components/dialogs/input-email-dialog/input-email-dialog.component';
import { CustomPaginator } from './pipes/custom-paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LogoutModalComponent } from './components/dialogs/logout-modal/logout-modal.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';

@NgModule({
  declarations: [ConfirmComponent, CapitalizePipe, InputEmailDialogComponent, LogoutModalComponent, ErrorDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatTableModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCardModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    FlexLayoutModule,
  ],
  exports: [CapitalizePipe, InputEmailDialogComponent, ConfirmComponent],
  providers: [
    CapitalizePipe,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MatPaginatorIntl, useClass: CustomPaginator },
  ],
})
export class SharedModule {}
