<div class="container">
  <h2 mat-dialog-title id="title">
    <mat-icon class="alert_icon">warning</mat-icon>
    {{ 'session_expired' | translate }}
  </h2>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div class="status_container">
      <p class="action-tax">
        <span>{{ 'session_expired_message' | translate }}</span>
      </p>
    </div>
    <div fxLayout="row" fxFill fxLayoutAlign="space-around" class="data-container">
      <div fxLayout="column" fxFlex="80">
          <div class="label-value-data">
            <span class="label-payment">
              {{ 'session_expired_info_part1' | translate }}
              <a href="#" (click)="redirectToBaseUrl($event)">{{ 'session_expired_info_link' | translate }}</a>
              {{ 'session_expired_info_part2' | translate }}
            </span>
          </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
