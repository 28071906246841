import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private transactionCompletedSource = new Subject<void>();

  transactionCompleted$ = this.transactionCompletedSource.asObservable();

  notifyTransactionCompleted() {
    this.transactionCompletedSource.next();
  }
}
