<h2 class="title">{{'tax_payment.sumary.title' | translate}}</h2>
<div class="div-table">
  <div class="searcher">
    <input (keyup)="applyFilter($event)" placeholder="{{'search'|translate}}">
  </div>
  <table mat-table [dataSource]="dataSource" class="tax-table">
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="arrow-button">
        <button mat-icon-button class="arrow" (click)="toggleRow(element)" *ngIf="element.description">
          <mat-icon>{{ element.expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="idtransaction">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.idtransaction" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.idTransaction }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.tax_description" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.documentMasterTypeName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.tax_type" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.documentMasterClassDesc }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.payment_date" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="date_column">{{ element.datePayment | date:'dd/MM/yyyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.tax_price" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="price_column">{{ element.amount | number : "1.2-2" }} {{element.currency}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.status" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.status | translate}} </td>
    </ng-container>

    <ng-container matColumnDef="registry">
      <th mat-header-cell *matHeaderCellDef>{{ "tax_payment.registry" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <a [href]="element.urlRegistry" target="_blank">{{ element.registryCode | translate}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{"actions" | translate}}</th>
      <td mat-cell *matCellDef="let element" class="buttons-td">
        <div class="div_buttons">
          <button mat-raised-button color="primary" *ngIf="element.urlCsv != null"
                  (click)="downloadReceipt(element.urlCsv)">
            {{'download_receipt'|translate}}
          </button>
          <button
          class="refund_button"
          *ngIf="(element.statusDetail === 3 || element.statusDetail === 0) && element.pendingStatus === 0 && element.registryCode == null"
          [disabled]="element.pendingRefund !== null"
          (click)="initRefund(element)"
        >
          {{'refund'|translate}}
        </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" *ngIf="element.detailRow">
          {{ element.description }}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"></tr>
  </table>
</div>
