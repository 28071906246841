import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataMasterValuesDTO } from '../model/datamaster';
import { API_CONFIG } from '../shared/api.config';

@Injectable({
  providedIn: 'root',
})
export class DataMasterService {
  constructor(private http: HttpClient) {}

  getDataMasterValues(): Observable<DataMasterValuesDTO> {
    const url = API_CONFIG.getDataMasters;
    return this.http.get<DataMasterValuesDTO>(url, { withCredentials: true });
  }

  generateToken(): Observable<string> {
    const url = API_CONFIG.generateToken;
    return this.http.get(url, { responseType: 'text', withCredentials: true });
  }
}
