import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Subscription, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBarComponent } from 'src/app/components/mat-snack-bar/mat-snack-bar.component';
import { Refund } from 'src/app/model/refund';
import { Tax } from 'src/app/model/tax';
import { TransactionInput } from 'src/app/model/transaction';
import { DataMasterService } from 'src/app/services/data-master.service';
import { TaxService } from 'src/app/services/tax.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { API_CONFIG } from 'src/app/shared/api.config';
import { ConfirmComponent } from 'src/app/shared/components/dialogs/confirm/confirm.component';
import { CurrencyStatusEnum } from 'src/app/shared/enum/currency-status.enum';
import { TransactionStatus } from 'src/app/shared/enum/transaction-status.enum';
import { ConfirmRefundComponent } from './confirm-refund/confirm-refund.component';
import { AuditoryService } from 'src/app/services/auditory.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss'],
})
export class MyPaymentsComponent {
  transactions: TransactionInput[];
  private updateInterval: any;
  private subscription: Subscription;
  displayedColumns: string[] = [
    'expand',
    'idtransaction',
    'description',
    'type',
    'date',
    'price',
    'status',
    'registry',
    'actions',
  ];
  dataSource: MatTableDataSource<TransactionInput>;
  expandedElement: Tax | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private taxService: TaxService,
    private authService: AuthService,
    private dataMaster: DataMasterService,
    public dialog: MatDialog,
    private transactionService: TransactionService,
    private snackBar: MatSnackBarComponent,
    private translate: TranslateService,
    private auditoryService: AuditoryService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.getMyPaymentList();
    this.updateInterval = setInterval(() => {
      this.getMyPaymentList();
    }, environment.intervalRefreshPayments);

    this.subscription = this.transactionService.transactionCompleted$.subscribe(
      () => {
        if (this.authService.isLoggedIn()) {
          this.getMyPaymentList();
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

  toggleRow(element: TransactionInput) {
    element.expand = !element.expand;
    this.dataSource.data = this.buildDataSource(this.transactions);
  }

  buildDataSource(data: TransactionInput[]): any[] {
    const expandedData: any[] = [];
    data.forEach((item) => {
      expandedData.push(item);
      if (item.expand) {
        expandedData.push({ ...item, detailRow: true });
      }
    });
    return expandedData;
  }

  isExpansionDetailRow = (index: number, row: any) => !!row.detailRow;

  /**
   * Get all taxes with pagination parameters
   *
   */
  getMyPaymentList() {
    this.taxService.getMyPayments().pipe(
      catchError((error) => {
        if (error.status === 401) {
          if (this.updateInterval) {
            clearInterval(this.updateInterval);
          }
        }
        return throwError(() => error);
      })
    ).subscribe(
      (data) => {
        data.forEach((transaction) => {
          switch (transaction.statusDetail) {
            case 0:
              transaction.status = TransactionStatus.REFUNDENDING;
              break;
            case 1:
              transaction.status = TransactionStatus.PENDING;
              break;
            case 3:
                transaction.status = TransactionStatus.SUCCESS;
              break;
            case 7:
              transaction.status = TransactionStatus.SUCCESSREFUND;
              break;
            case 9:
              transaction.status = TransactionStatus.DENIED;
              break;
            case 11:
              transaction.status = TransactionStatus.REJECT;
              break;
            case 12:
              transaction.status = TransactionStatus.BLACKLIST;
              break;
          }
          switch (transaction.currency) {
            case CurrencyStatusEnum.USD:
              transaction.currency = '$';
              break;
            case CurrencyStatusEnum.EURO:
              transaction.currency = '€';
              break;
          }
        });
        this.transactions = data;
        this.dataSource = new MatTableDataSource(data);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  initRefund(transaction: TransactionInput) {
    const dialogRef = this.dialog.open(ConfirmRefundComponent, {
      width: '45%',
      data: {
        ...transaction,
        insertdate: this.utilsService.formatDate(transaction.insertdate)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (
          transaction.status == TransactionStatus.SUCCESS &&
          transaction.registryCode == null
        ) {
          this.dataMaster.generateToken().subscribe((data) => {
            if (data) {
              this.refund(transaction, data);
            }
          });
        } else {
          this.snackBar.openSnackBar(
            this.translate.instant('error.not_refund'),
            this.translate.instant('CLOSE'),
            'red-snackbar'
          );
        }
        transaction.pendingRefund = 1;
      }
    });
  }

  async refund(transaction: TransactionInput, token: string) {
    try {
      if (!token) {
        throw new Error('Token is undefined or null');
      }
      const baseUrl = localStorage.getItem('baseUrl');
      if (!baseUrl) {
        throw new Error('Base URL is not defined in localStorage');
      }

      const apiUrl = baseUrl.concat('refund/');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Token': token,
        },
        body: JSON.stringify({
          transaction: {
            id: transaction.idTransaction,
          },
        }),
      };

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      let action = 'payment.refund.faliure';
      if (data.status === 'success') {
        action = 'payment.refund.success';
        transaction.status = TransactionStatus.REFUNDENDING;
        transaction.pendingRefund = 1;
        this.dataSource.data = [...this.dataSource.data];
      }

      const auditoryInputDTO = {
        idTransaction: transaction.idTransaction,
        request: environment.refund,
        body: JSON.stringify(data),
        dateAuditory: new Date().toISOString(),
      };

      this.auditoryService.saveAuditory(auditoryInputDTO).subscribe({
        next: () => console.log('Auditoría guardada correctamente'),
        error: (err) => console.error('Error al guardar la auditoría:', err),
      });

      this.openDialogConfirm(transaction, action);
      const refund: Refund = {
        idTransaction: transaction.idTransaction,
        statusDetail: data.detail,
        status: data.status,
        refundDate: new Date(),
      };
      this.taxService.saveRefund(refund).subscribe({
        next: () => {
          console.log('Reembolso guardado satisfactoriamente');
        },
        error: (error) => {
          console.error('Error al guardar el reembolso: Error en el reembolso con ID', transaction.idTransaction, " ", error);
        },
      });
      return data;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  openDialogConfirm(transaction: TransactionInput, action: string): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '45%',
      data: {
        idtransaction: transaction.idTransaction,
        title: transaction.documentMasterTypeName,
        action: action,
        date: this.utilsService.formatDate(new Date().toISOString()),
        tax: transaction.documentMasterTypeName,
        description: transaction.description,
        fullname: localStorage.getItem(API_CONFIG.localStorage.username),
        amount: transaction.amount,
        currency: transaction.currency,
      },
    });
    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.cdr.detectChanges();
    });
    dialogRef.componentInstance.close.subscribe(() => {});
  }

  downloadReceipt(url: string) {
    window.open(url, '_self');
  }
}
