import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InputEmailDialogComponent } from 'src/app/shared/components/dialogs/input-email-dialog/input-email-dialog.component';
import { API_CONFIG } from 'src/app/shared/api.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}
  token: string;
  isLoggedIn = false;
  ngOnInit() {
    this.onLogin();
  }

  onLogin(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.token = params.get('token');
    });
    this.authService.validateToken(this.token).subscribe((isValid) => {
      if (isValid) {
        this.isLoggedIn = true;
        this.authService.getUserData().subscribe((data) => {
          let username = `${data.name} ${data.surname}`;
          localStorage.setItem(API_CONFIG.localStorage.sede_url, data.sedeUrl);
          if (data.secondSurname) {
            username += ` ${data.secondSurname}`;
          }
          if (data.email) {
            this.authService.setUser({
              name: data.name,
              surname: data.surname,
              secondSurname: data.secondSurname,
              email: data.email,
              idindividualsmaster: data.idindividualsmaster,
              username: username,
            });
            this.redirectMain();
          } else {
            const dialogEmail = this.dialog.open(InputEmailDialogComponent, {
              width: '25%',
              disableClose: true,
              data: {
                title: this.translate.instant('dialog.email.title'),
                content: this.translate.instant('dialog.email.content'),
                inputLabel: this.translate.instant('dialog.email.label'),
              },
            });
            dialogEmail.componentInstance.accept.subscribe((email) => {
              this.authService.setUser({
                name: data.name,
                surname: data.surname,
                secondSurname: data.secondSurname,
                email: email,
                idindividualsmaster: data.idindividualsmaster,
                username: username,
              });
              this.redirectMain();
            });
          }
        });
      }
    });
  }

  redirectMain() {
    this.router.navigate(['/main']);
  }
}
